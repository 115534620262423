import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import {Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import { connect } from 'react-redux'
import ReceiptsForm from '../ReceiptsForm/ReceiptsForm'
import ReceiptsListRow from '../ReceiptsListRow/ReceiptsListRow'
import { Link, Redirect } from 'react-router-dom'
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import { checkGroupPermission, getGroupPermission } from '../../../services/grouppermission'
import classnames from "classnames";

class ReceiptsList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      receipts: '',
      toggle_receipts: false,
      receipts_id: 0,
      w_receipts_image_file: false,
      width: 0,
      height: 0,
      menuID: [1101, 1102],
      grouppermissions: [],
      btnClick: 0,
      receipts_selected_id: 0,
      is_vat: 0,
      form_status: 'add',
      activeTab: '1',
      user: JSON.parse(this.props.user),
    }
    this.toggleReceipts = this.toggleReceipts.bind(this);
    this.onDeleteHandle = this.onDeleteHandle.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.openReceiptsFormAdd = this.openReceiptsFormAdd.bind(this);
    this.openReceiptsFormEdit = this.openReceiptsFormEdit.bind(this);
    this.loadReceiptsData = this.loadReceiptsData.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleOnClick (action, receipts) {

    this.setState({
      'btnClick': action,
      'receipts_selected_id': receipts.receipts_id,
    })
  }

  loadReceiptsData () {
    // 3 = รายรับของเต็นท์รถ
    axios.get(APIURL + '/receipts/3')
      .then(res => {
        this.setState({lists: res.data})
      }).catch(error => {
        this.setState({lists: []})
    })
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidMount () {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()
    //if(this.state.load_data)

    this.loadCompanySetting()
    this.loadGroupPermission()
    this.loadReceiptsData()

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  loadCompanySetting() {
    axios.get(APIURL + '/company_setting/1').then(res => {
      const data = res.data;
      const company_setting = data.company_setting;
      const is_vat = company_setting.is_vat;
      // const vatPercent = is_vat === 1 ? company_setting.vat/100.0 : 0;
      // const vat = is_vat === 1 ? company_setting.vat : 0;

      this.setState({
        is_vat
      });
    })
  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID

    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  toggleReceipts () {
    this.setState({
      toggle_receipts: !this.state.toggle_receipts
    })
    // ,() => this.loadReceiptsData()
  }

  openReceiptsFormAdd() {
    this.setState({
      toggle_receipts: true,
      form_status: 'add',
      receipts_selected: null
    })
  }

  openReceiptsFormEdit(receipts) {
    this.setState({
      toggle_receipts: true,
      form_status: 'edit',
      receipts_selected: receipts
    })
  }

  onDeleteHandle(receipt) {

    if(window.confirm('ยืนยันการลบรายการ '+receipt.receipts_name)){
      let receipts_id = receipt.receipts_id
      let receipts_image_file = receipt.receipts_image_file
      let data = {
        receipts_image_file: receipts_image_file
      }

      axios.put(APIURL+'/receipts/'+receipts_id, data)
        .then(res => {
          if(res.status === 200){
            this.loadReceiptsData()
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {
    const {
      width,
      grouppermissions,
      btnClick,
      toggle_receipts
    } = this.state

    if (btnClick === 'receipts/income-tax-invoice') {
      const receipts_selected_id = this.state.receipts_selected_id
      const target = '/receipts/income-tax-invoice/?receipts_id=' + receipts_selected_id;
      return <Redirect push to={target} target={"_blank"} />;
    }

    return (
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
              >
                <i className="icon-printer"/> รายรับอื่นๆของเต็นท์
              </NavLink>
            </NavItem>

            {/*<NavItem>
              <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
              >
                <i className="icon-badge"/> รายรับรวม
              </NavLink>
            </NavItem>*/}

          </Nav>

          <TabContent activeTab={this.state.activeTab}>

            <TabPane tabId="1">

              <div className="card">
        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-success">
                <div className="card-header card-customer">
                  <strong className="text-title"> ข้อมูลรายรับ </strong>

                  {/*{
                    this.state.user.type === 'admin' || this.state.user.type === 'manager'
                    || this.state.user.type === 'accountx'
                    || this.state.user.type === 'account'
                        ? (
                        <Link to="/report/receipts">
                          <button className="btn btn-head-bar float-right ml-1 mr-1" style={{backgroundColor: '#4dbd74'}}>
                            <i className="icon-doc"></i>&nbsp; รายงานรายรับ
                          </button>
                        </Link>
                    ) : null
                  }*/}

                  {
                    checkGroupPermission(1101, grouppermissions).printed ?
                      <Link to="/report/receipts">
                        <button className="btn btn-head-bar float-right ml-1 mr-1" style={{backgroundColor: '#4dbd74'}}>
                          <i className="icon-doc"></i>&nbsp; รายงานรายรับ
                        </button>
                      </Link>
                      : null
                  }

                  {
                    checkGroupPermission(1101, grouppermissions).created ?
                      <button type="button"
                              onClick={this.openReceiptsFormAdd}
                              className="btn btn-head-bar float-right">
                        <i className="icon-plus"/>&nbsp; เพิ่มข้อมูลรายรับ
                      </button>
                      : null
                  }


                  <Modal isOpen={this.state.toggle_receipts} toggle={this.toggleReceipts}
                         className={'modal-lg'}
                         style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                  >
                    <ModalBody>
                      {
                        toggle_receipts === true && <ReceiptsForm
                          onToggle={this.toggleReceipts}
                          form_status={this.state.form_status}
                          receipts_selected={this.state.receipts_selected}
                          onReloadReceipts={this.loadReceiptsData}
                        />
                      }
                    </ModalBody>
                  </Modal>
                </div>

                <ReceiptsListRow
                   receipts_list={this.state.lists}
                   grouppermissions={this.state.grouppermissions}
                   is_vat={this.state.is_vat}
                   onDeleteHandle={this.onDeleteHandle}
                   handleOnClick={this.handleOnClick}
                   onOpenReceiptsFormEdit={this.openReceiptsFormEdit}
                />

              </div>

            </div>
          </div>
        </div>
      </div>

            </TabPane>

            <TabPane tabId="2">
              <div>รายรับรวม</div>
            </TabPane>

          </TabContent>

        </div>
          )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ReceiptsList)
